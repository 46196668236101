import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import PdfButton from "../components/PdfButton"
import { data, requirements, efpDocuments } from "../data/certification-efp"
import parse from "react-html-parser"
import Iva from "../components/Iva"
import AnnouncementEfp from "../components/Announcement/AnnouncementEfp"
import TitlePage from "../components/TitlePage"
import { useAxiosClient } from "../service/useAxiosClient"

export default function CertificationTestEFP({ location }) {
  const id = 4
  const [test, setTest] = useState()
  const [price, setPrice] = useState()

  const [{ data: dataTest }] = useAxiosClient({
    url: `/exams/dates/web/next-calls/?exam_type=EFP`,
    method: "GET",
  })

  useEffect(() => {
    if (dataTest && dataTest.EFP) {
      const [test] = dataTest.EFP.calls
      setTest(test)
    }
  }, [dataTest])

  const [{ data: dataPrice }] = useAxiosClient({
    url: `/professional-certificates/web/exam-price/?professional_certificate=${id}`,
    method: "GET",
  })

  useEffect(() => {
    if (dataPrice) {
      setPrice(dataPrice)
    }
  }, [dataPrice])

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    {
      label:
        "Exámenes certificación EFPA <em>European Financial Planner</em> (EFP)",
    },
  ]
  const renderContent = () => {
    return (
      data &&
      data.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }
  const renderContentRight = () => {
    return (
      requirements &&
      requirements.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }
  const renderDocuments = () => {
    return (
      efpDocuments &&
      efpDocuments.map(item => {
        return (
          <PdfButton
            isExternal
            className="mb-2"
            text={item.name}
            link={item.link}
          />
        )
      })
    )
  }
  return (
    <Layout>
      <TitlePage
        title="Exámenes certificación EFPA European Financial Planner (EFP)"
        url={location.pathname}
      />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Exámenes certificación EFPA <em>European Financial Planner</em> (EFP)"
          icon="fas fa-sitemap"
        />
        <div className="CertificationTestEFP">
          <div className="row efpa-row">
            <div className="col-12 col-md-6 Certification-padding-r">
              {renderContent()}
              <div className="Certification-content efpa-content">
                <TitleBlock
                  className="Certification-title-block"
                  title="Simulación examen"
                  icon="fas fa-file-alt"
                />
                <PdfButton
                  isExternal
                  className="mb-2"
                  text="Simulación parte teórica"
                  link="/docs/simulacion-efp-teorica.pdf"
                />
                <PdfButton
                  isExternal
                  className="mb-2"
                  text="Simulación parte práctica"
                  link="/docs/simulacion-efp.pdf"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 Certification-padding-l">
              {renderContentRight()}
              <div className="Certification-content efpa-content">
                <TitleBlock
                  className="Certification-title-block"
                  title="Documentación"
                  icon="fas fa-file-alt"
                />
                {renderDocuments()}
              </div>
              {test && (
                <div className="Certification-content">
                  <AnnouncementEfp
                    title={test?.call_text}
                    date={test?.exams_date_text}
                    link={test?.inscription_link}
                  />
                </div>
              )}
              <TitleBlock className="Certification-title-block" title="Coste" />
              <Iva
                price={`${price} € (IVA incluido)`}
                text="30% de descuento para EFA's."
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
